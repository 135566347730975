<script>
import { ref, computed, inject } from "vue";

import Switch from "./Switch.vue";
import strftime from "strftime";
import { showErrorMessage } from "../utils/errorProcessing.js";
import { PlusSmIcon as PlusSmIconSolid } from "@heroicons/vue/solid";
import { MinusSmIcon as MinusSmIconSolid } from "@heroicons/vue/solid";

export default {
  components: {
    Switch,

    PlusSmIconSolid,
    MinusSmIconSolid,
  },
  props: {
    options: Object,
    label: String,
    selectedOption: Object,
    sfd: Object,
    item: Object,
  },
  // {options: Object, label: String, onChangeFunc: String},

  setup(props, context) {
    const item_id = props.item.url; //really item_url not item_id!!

    const isShipping = ref(props.sfd.shipping);
    //console.log(
    //   "isShipping = " +
    //     JSON.stringify(isShipping) +
    //     "for props.sfd.state == " +
    //     props.sfd.state
    // );
    //console.log("isShipping = " + isShipping.value);
    const isActive = computed(() => ({
      "opacity-50": !isShipping.value,
    }));

    const axios = inject("axios"); // inject axios

    const clearSelection = () => {
      axios
        .put(props.sfd.clear_link, { item_id, item_page: true })
        .then((response) => {
          //console.log(response);
          context.emit("updateSFD", response.data);
        })
        .catch((err) => console.log(err));
    };

    const turnOnDay = () => {
      //console.log("turning on shipping");
      const api = props.sfd.ship_url;
      return axios
        .put(api, { item_id, item_page: true })
        .then((response) => {
          console.log(" the response from turnOnDay is ", response.data);
          return response;
        })
        .catch((err) => {
          showErrorMessage(err);
        });
    };

    const turnOffDay = () => {
      //console.log("turning off shipping");
      const api = props.sfd.skip_url;

      return axios
        .put(api, { item_id, item_page: true })
        .then((response) => {
          //console.log(" the response from turnOffDay is ", response.data);

          return response;
        })
        .catch((response) => showErrorMessage(response));
    };

    const toggleSwitch = () => {
      //console.log("the sfd started as  ", JSON.stringify(props.sfd));
      //console.log("toggling sfd.shipping = " + props.sfd.shipping);

      if (props.sfd.state == "shipping") {
        //console.log(
        //   "We are turning off the day because shipping is true ",
        //   JSON.stringify(props.sfd)
        // );
        turnOffDay().then((response) => {
          context.emit("updateSFD", response.data);
          //console.log("the sfd is now ", JSON.stringify(props.sfd));
        });
      } else {
        turnOnDay().then((response) => {
          //console.log("the sfd is now ", JSON.stringify(props.sfd));
          context.emit("updateSFD", response.data);
        });
      }
    };

    return {
      props,
      isActive,
      strftime,
      isShipping,
      toggleSwitch,
      clearSelection,
    };
  },
};
</script>

<template>
  <section aria-labelledby="fulfillment-day" :sfd_id="props.sfd.id">
    <div
      :id="'fulfillment_day-' + props.sfd.fulfillment_day.id"
      class="bg-white shadow sm:rounded-lg w-full"
      :class="isActive"
    >
      <div class="grid grid-cols-2 sm:gap-x-4 sm:gap-y-8 sm:grid-cols-2">
        <div class="px-4 py-5 sm:px-6 sm:col-span-1">
          <h2
            id="applicant-information-title"
            class="text-lg leading-6 font-medium text-gray-500"
          >
            Roast Day
          </h2>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            {{ isShipping ? "Shipping" : "" }}
            <span class="text-md text-gray-900 font-bold">
              {{
                strftime("%A, %b %d", new Date(props.sfd.fulfillment_day.nominal_day))
              }}
            </span>
          </p>
        </div>
        <div class="flex justify-end col-span-1 sm:col-span-1 pr-8">
          <Switch
            v-if="sfd['changeable?']"
            v-model="isShipping"
            question="Ship"
            :enabled="isShipping"
            @updateMe="toggleSwitch"
          />
        </div>
      </div>
      <transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="transform opacity-0 scale-75"
        enter-to-class="opacity-100 scale-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-75"
      >
        <div
          v-if="isShipping"
          class="border-t border-gray-200 px-4 py-5 sm:px-6"
        >
          <div v-if="props.sfd.item">
            <dl
              v-if="props.sfd.item"
              class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2"
            >
              <div class="sm:col-span-2">
                <div
                  v-if="props.sfd.item"
                  v-html="props.sfd.item.image_html_tag"
                ></div>
              </div>
              <div v-if="props.sfd.item" class="sm:col-span-1">
                <dt class="text-sm font-bold text-gray-900">
                  {{ props.sfd.item.name }}
                </dt>
                <dd class="mt-1 text-sm text-gray-900">
                  {{ props.sfd.item.subheading }}
                </dd>

                <dd
                  v-if="props.sfd.item"
                  class="mt-1 text-sm text-gray-500"
                  v-html="props.sfd.item.headline"
                />
                <dd
                  v-if="props.sfd['changeable?']"
                  class="mt-1 text-sm text-gray-900"
                >
                  <a
                    :href="props.sfd.choose_link"
                    class="underline hover:text-gray-500"
                  >
                    Change
                  </a>
                </dd>
                <dd
                  v-if="props.sfd['changeable?']"
                  class="mt-1 text-sm text-gray-900"
                >
                  <a
                    href="#"
                    method="put"
                    class="underline hover:text-gray-500"
                    @click.prevent="clearSelection"
                  >
                    Clear
                  </a>
                </dd>
              </div>
              <div class="sm:col-span-1 flex justify-end">
                <div>
                  <dt class="text-sm font-medium text-gray-500">
                    Shipment Size
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 font-bold">
                    {{ props.sfd.inventory_quantity }} oz
                  </dd>
                </div>
              </div>
            </dl>
          </div>
          <div v-else>
            <dl class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div class="sm:col-span-1">
                <dt
                  v-if="props.sfd['changeable?']"
                  class="text-sm font-medium text-gray-500"
                >
                  Selection
                </dt>

                <div class="sm:col-span-2">
                  <dd class="mt-1 text-sm text-gray-900">
                    As it stands we we will select the best coffee for you based
                    on your previous feedback
                  </dd>
                </div>
                <dd
                  v-if="props.sfd['changeable?']"
                  class="mt-1 text-sm text-gray-900"
                >
                  <a
                    :href="props.sfd.choose_link"
                    class="underline hover:text-gray-500"
                  >
                    <div class="mt-4 mb-4 w-full">
                      <button
                        type="button"
                        class="
                          items-center
                          px-6
                          py-3
                          border border-transparent
                          text-base
                          font-medium
                          rounded-md
                          shadow-sm
                          text-white
                          bg-violet-600
                          hover:bg-violet-700
                          focus:outline-none
                          focus:ring-2
                          focus:ring-offset-2
                          focus:ring-violet-500
                          w-full
                        "
                      >
                        Select Coffee
                      </button>
                    </div>
                  </a>
                </dd>
              </div>

              <div class="sm:col-span-1 sm:ml-auto mr-8 flex justify-end">
                <div>
                  <dt class="text-sm font-medium text-gray-500">
                    Shipment Size
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 font-bold">
                    {{ props.sfd.inventory_quantity }} oz
                  </dd>
                </div>
              </div>
            </dl>
          </div>
          <div class="flex justify-end">
            <span v-if="props.sfd.deletable" class="mr-4 w-5 inline-block">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  p-1
                  border border-transparent
                  rounded-full
                  shadow-sm
                  text-white
                  bg-violet-600
                  hover:bg-violet-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-violet-500
                "
                @click="$emit('removeSFD', props.sfd)"
              >
                <MinusSmIconSolid class="h-5 w-5" aria-hidden="true" />
              </button>
            </span>
            <span v-if="props.sfd['changeable?']" class="mr-4 w-5 inline-block">
              <button
                type="button"
                class="
                  inline-flex
                  items-center
                  p-1
                  border border-transparent
                  rounded-full
                  shadow-sm
                  text-white
                  bg-violet-600
                  hover:bg-violet-700
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-violet-500
                "
                @click="$emit('addSFD', props.sfd)"
              >
                <PlusSmIconSolid class="h-5 w-5" aria-hidden="true" />
              </button>
            </span>
          </div>
        </div>
      </transition>
    </div>
  </section>
</template>
